import React, { Component } from "react"
import { graphql, navigate, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import i18Data from '../../content/intl/labels.json';
import { getTranslations, changeToLocalLinks, categoriesStringToSlugsArray, createMetaDesc } from "../components/helpers/helpers";
import Share from "../components/share";

import "../styles/publications.scss"

class Publications extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    doSearch(ev) {
        console.log(this.searchTextInput.value);
    }

    render() {
        const { data: { pageData, pubParents }, pageContext: { translateKey, lang, slug, pubsPageSlug, prefix } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const i18 = i18Data[ lang ];
        const url = `${prefix}/${pubsPageSlug}/${slug}`;

        const pubsParentsSlugs = {};
        pubParents.edges.forEach(({ node }) => {
            pubsParentsSlugs[ node.frontmatter.lang ] = node.frontmatter.slug;
        });
        const translations = getTranslations(pageData.edges, translateKey, pubsParentsSlugs);
        const textDesc = createMetaDesc(page.frontmatter.card);

        return (
            <Layout mainClassName="publications" data={{}} noActiveMenu={false} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={textDesc} lang={lang ? lang : "en" }/>

                <div className="publications-container initial-padding">

                    <div className="publications-top" >
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.title }}></h1>
                        <div className="card-inside" dangerouslySetInnerHTML={{ __html: page.frontmatter.card }} /> 
                        
                    </div>

                    <div className={`publication-content ${!!page.frontmatter.specialLink ? "special-link" : ""}`}>
                        { page.frontmatter.specialLink &&
                            <a className="download-file" 
                                rel="noreferrer" aria-label={i18.labels.download}
                                href={page.frontmatter.specialLink} dangerouslySetInnerHTML={{ __html: i18.labels.download }} 
                                target="_blank" />
                        }

                        { page.html && 
                        <>
                            <h2>{i18.labels.presentation}</h2>
                            <div className="is-html" dangerouslySetInnerHTML={{ __html: page.html }} ref={(t) => { this.isHtml = t }} />            
                        </>
                        }

                        <div className="relations">
                            { page.frontmatter.categories &&
                            <div className="relations-section">
                                <h4>{i18.labels.categories}</h4>
                                <ul>
                                {categoriesStringToSlugsArray(page.frontmatter.categories).map(cat => {
                                    return(
                                        <li key={cat.slug}>
                                        <Link key={cat.slug} to={`${prefix}/${pubsPageSlug}/tag/${cat.slug}`} rel="category tag">
                                            {cat.text}
                                        </Link>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                            }
                            { page.frontmatter.areas &&
                            <div className="relations-section">
                                <h4>{i18.labels.areas}</h4>
                                <ul>
                                {categoriesStringToSlugsArray(page.frontmatter.areas).map(cat => {
                                    return(
                                        <li key={cat.slug}>
                                        <Link key={cat.slug} to={`${prefix}/${pubsPageSlug}/area/${cat.slug}`} rel="category tag">
                                            {cat.text}
                                        </Link>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                            }
                        </div>
                    </div>


                    <Share url={i18Data.common.url + url} lang={lang} title={page.frontmatter.title} />

                </div>

            </Layout>
        )
    }
}

export default Publications


export const query = graphql`
    query PublicationQuery($translateKey: String!) {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "publication" }, translateKey: { eq: $translateKey } } }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        title
                        translateKey
                        order
                        card
                        pubDate
                        specialLink
                        categories
                        areas
                    }
                    html

                }
            }
        }
        pubParents: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "publications" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        slug
                    }
                    id
                    html

                }
            }
        }
    }
`