import React from "react"
import i18Data from '../../content/intl/labels.json';

function Share({ url, title, lang }) {

    const i18 = i18Data[ lang ];
    const escapedUrl = encodeURI(url);

	return (
        <div className="share-on">
            <h5>{i18.labels.shareOn}</h5>
            <ul>
                <li>
                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/share?url=${escapedUrl}&text=${title}`}>Twitter</a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=${escapedUrl}&summary=${title}`}>Linked-in</a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${escapedUrl}`}>Facebook</a>
                </li>
            </ul>
        </div>

    )
}

export default Share
